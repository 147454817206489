// scroll to contact form
const freeTrialButton = document.getElementById('free-trial');
freeTrialButton.onclick = scrollToRegistration;

const contactUsButton = document.getElementById('contact-us');
contactUsButton.onclick = scrollToContact;

function scrollToContact() {
  document.getElementById('contact').scrollIntoView();
}

function scrollToRegistration() {
  document.getElementById('register').scrollIntoView();
}

// set active menu item
function isElementInViewport (el) {
  const rect = el.getBoundingClientRect();
  return (
    rect.top < 0 ||
    (rect.top >= 0 && rect.top < (window.innerHeight || document.documentElement.clientHeight) / 2)
  );
}

function scrollHandler() {
  const menuItems = document.getElementsByClassName('menu-item');
  for (let i = 0; i < menuItems.length; i++) {
    menuItems[i].className = 'menu-item';
  }
  const mobileMenuItems = document.getElementsByClassName('mobile-menu-item');
  for (let i = 0; i < mobileMenuItems.length; i++) {
    mobileMenuItems[i].className = 'mobile-menu-item';
  }
  if (isElementInViewport(document.getElementById('contact'))) {
    document.getElementById('contact-menu-item').className += ' active';
    document.getElementById('contact-mobile-menu-item').className += ' active';
  } else if (isElementInViewport(document.getElementById('register'))) {
    document.getElementById('register-menu-item').className += ' active';
    document.getElementById('register-mobile-menu-item').className += ' active';
  } else if (isElementInViewport(document.getElementById('faq'))) {
    document.getElementById('faq-menu-item').className += ' active';
    document.getElementById('faq-mobile-menu-item').className += ' active';
  } else if (isElementInViewport(document.getElementById('about'))) {
    document.getElementById('about-menu-item').className += ' active';
    document.getElementById('about-mobile-menu-item').className += ' active';
  } else if (isElementInViewport(document.getElementById('features'))) {
    document.getElementById('features-menu-item').className += ' active';
    document.getElementById('features-mobile-menu-item').className += ' active';
  } else {
    document.getElementById('home-menu-item').className += ' active';
    document.getElementById('home-mobile-menu-item').className += ' active';
  }
}

addEventListener('DOMContentLoaded', scrollHandler, false);
addEventListener('load', scrollHandler, false);
addEventListener('scroll', scrollHandler, false);
addEventListener('resize', scrollHandler, false);

// mobile menu
const burger = document.getElementById('burger');
burger.onclick = showMobileMenu;
const close = document.getElementById('close');
close.onclick = hideMobileMenu;
const mobileMenuItems = document.getElementsByClassName('mobile-menu-item');
for (let i = 0; i < mobileMenuItems.length; i++) {
  mobileMenuItems[i].onclick = hideMobileMenu;
}

function showMobileMenu() {
  document.getElementById('mobile-menu').className = 'visible';
  document.getElementById('close').className = '';
  document.getElementById('burger').className = 'hidden';
}

function hideMobileMenu() {
  document.getElementById('mobile-menu').className = '';
  document.getElementById('close').className = 'hidden';
  document.getElementById('burger').className = '';
}

// change language
const cookieLanguage = (document.cookie
  .split('; ')
  .find(row => row.startsWith('language=')) || '')
  .split('=')[1];

const language = document.getElementById("language");
const mobileLanguage = document.getElementById("mobile-language");

if (cookieLanguage) {
  language.value = cookieLanguage;
  mobileLanguage.value = cookieLanguage;
}

language.onchange = setLanguageDesktop;
mobileLanguage.onchange = setLanguageMobile;

function setLanguageDesktop(event) {
  const value = event.currentTarget.value;
  mobileLanguage.value = value;
  setLanguage(value);

}
function setLanguageMobile(event) {
  const value = event.currentTarget.value;
  language.value = value;
  setLanguage(value);
}
function setLanguage(value) {
  document.cookie = `language=${value};max-age=31536000; path=/`; // a year
  window.location.href = `/${value}`;
}

// forms
// register
const emailRE = /\S+@\S+\.\S+/;

const registerSubmitButton = document.getElementById("reg-subm-btn");
const registerEmail = document.getElementById("email-reg");
const emailRegisterError = document.getElementById("email-reg-error");
const passwordRegisterError = document.getElementById("pwd-register-error");
const registerPassword = document.getElementById("reg-pwd");
const registerSubmitError = document.getElementById("register-submit-failed");

function register() {
  const emailValue = registerEmail.value;
  const passwordValue = registerPassword.value;

  let canSubmit = true;

  if (!emailValue || !emailRE.test(emailValue)) {
    canSubmit = false;
    emailRegisterError.className += " visible";
    registerEmail.className = "with-error";
  }

  if (!passwordValue) {
    canSubmit = false;
    passwordRegisterError.className += " visible";
    registerPassword.className = "with-error";
  }

  if (canSubmit) {
    const body = {
      Email: emailValue,
      Pwd: passwordValue,
      Language: language.value || 'en'
    }
    const init = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body),
    };

    const request = new Request('/api/v1/create_user', init);

    return fetch(request).then(response => {
      try {
        if (response.status === 200) {
          registerSuccess()
          clearRegisterInputs()

        } else {
          registerResponseError()
        }
      } catch (e) {
        registerResponseError()
      }
    });
  }
}

function registerSuccess() {
  window.location.href = "https://app.eksenia.com/login";
}

function registerResponseError() {
  registerSubmitError.className = "visible";

  setTimeout(() => {
    registerSubmitError.className = "";
  }, 10000);
}

function clearRegisterInputs() {
  registerEmail.value = "";
  registerPassword.value = "";
}

function resetRegisterEmailError() {
  registerEmail.className = "";
  emailRegisterError.className = "error-message";
}

function resetRegisterPasswordError() {
  registerPassword.className = "";
  passwordRegisterError.className = "error-message";
}

registerSubmitButton.onclick = register;
registerEmail.onkeydown = resetRegisterEmailError;
registerPassword.onkeydown = resetRegisterPasswordError;

// contact
const submitButton = document.getElementById("subm-btn");
const name = document.getElementById("name");
const dec = document.getElementById("dec");
const email = document.getElementById("email");
const phone = document.getElementById("phone");
const property = document.getElementById("property");
const rooms = document.getElementById("rooms");
const message = document.getElementById("message");
const nameError = document.getElementById("name-error");
const emailError = document.getElementById("email-error");
const messageError = document.getElementById("message-error");
const submitError = document.getElementById("submit-failed");
const submitSuccess = document.getElementById("submit-success");

function submitForm() {
  const decoyValue = dec.value;
  if (decoyValue) {
    return
  }

  const nameValue = name.value;
  const emailValue = email.value;
  const messageValue = message.value;
  let canSubmit = true;

  if (!nameValue) {
    canSubmit = false;
    nameError.className += " visible";
    name.className = "with-error";
  }

  if (!emailValue || !emailRE.test(emailValue)) {
    canSubmit = false;
    emailError.className += " visible";
    email.className = "with-error";
  }

  if (!messageValue) {
    canSubmit = false;
    messageError.className += " visible";
    message.className = "with-error";
  }

  if (canSubmit) {
    const phoneValue = phone.value;
    const propertyValue = property.value;
    const roomsValue = rooms.value;

    const body = {
      Name: nameValue,
      Email: emailValue,
      Message: messageValue,
      Phone: phoneValue || '',
      Property: propertyValue || '',
      Rooms: roomsValue || '',
      Language: language.value || 'en'
    }
    const init = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body),
    };

    const request = new Request('/api/v1/web_contact', init);

    return fetch(request).then(response => {
      try {
        if (response.status === 200) {
          responseSuccess()
          clearInputs()

        } else {
          responseError()
        }
      } catch (e) {
        responseError()
      }
    });
  }
}

function clearInputs() {
  name.value = "";
  email.value = "";
  phone.value = "";
  property.value = "";
  rooms.value = "";
  message.value = "";
}

function responseError() {
  submitError.className = "visible";

  setTimeout(() => {
    submitError.className = "";
  }, 10000);
}

function responseSuccess() {
  submitSuccess.className = "visible";

  setTimeout(() => {
    submitSuccess.className = "";
  }, 10000);
}

submitButton.onclick = submitForm;
name.onkeydown = resetNameError;
email.onkeydown = resetEmailError;
message.onkeydown = resetMessageError;

function resetNameError() {
  name.className = "";
  nameError.className = "error-message";
}

function resetEmailError() {
  email.className = "";
  emailError.className = "error-message";
}

function resetMessageError() {
  message.className = "";
  messageError.className = "error-message";
}

